import { CircularProgress } from "@mui/material";
import { LoadingAnimationProps } from "./LoadingAnimation.types";

export const LoadingAnimation = ({ sx, visibility = true, ...rest }: LoadingAnimationProps) => {
  return (
    <CircularProgress
      {...rest}
      sx={{ mx: "auto", display: visibility ? "block" : "none", ...sx }}
    />
  );
};
