export const routes = {
  home: () => "/",
  dashboard: () => "/dashboard",
  login: () => "/login",
  userManagement: () => "/user-management",
  edu: () => {
    const eduRoot = "/edu";
    return {
      init: eduRoot,
      categories: () => `${eduRoot}/categories`,
      posts: () => `${eduRoot}/posts`,
    };
  },
  content: () => {
    const contentRoot = "/content";
    return {
      init: contentRoot,
      generalRecommendations: () => `${contentRoot}/general-recommendations`,
      goalSuggestions: () => `${contentRoot}/goal-suggestions`,
      freqAskedQuestions: () => `${contentRoot}/frequently-asked-questions`,
    };
  },
  sleepAidMaterials: () => "/sleep-aid-materials",
  treatments: () => "/treatments",
};
