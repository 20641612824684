import { Box, Paper, SvgIcon, Typography } from "@mui/material";
import { StatisticItemProps } from "./StatisticItem.types";
import { SxStyles } from "../../theme";
import { CardMembership } from "@mui/icons-material";
import CancelPresentationOutlinedIcon from "@mui/icons-material/CancelPresentationOutlined";
import PersonIcon from "@mui/icons-material/Person";
import PsychologyIcon from "@mui/icons-material/Psychology";
import { LoadingAnimation } from "../LoadingAnimation/LoadingAnimation";
import { OverflowTooltip } from "../OverflowTooltip/OverflowTooltip";

export const StatisticItem = ({
  label,
  value,
  icon,
  color,
  additionalInfo,
  isLoading = false,
}: StatisticItemProps) => {
  return (
    <Paper
      sx={{
        ...sx.itemContainer,
        backgroundColor: `${color}.main`,
      }}
    >
      {isLoading ? (
        <Box sx={sx.loadingWrapper}>
          <LoadingAnimation />
        </Box>
      ) : (
        <>
          <Box sx={sx.info}>
            <Typography sx={sx.value}>
              <OverflowTooltip title={value?.toString() || ""}>{value}</OverflowTooltip>
            </Typography>
            <SvgIcon
              component={() => {
                switch (icon) {
                  case "activeUsers":
                    return <PersonIcon sx={sx.icon} />;
                  case "subscription":
                    return <CardMembership sx={sx.icon} />;
                  case "churn":
                    return <CancelPresentationOutlinedIcon sx={sx.icon} />;
                  case "annualSubscription":
                    return <CardMembership sx={sx.icon} />;
                  case "monthlySubscription":
                    return <CardMembership sx={sx.icon} />;
                  case "therapyActivated":
                    return <PsychologyIcon sx={sx.icon} />;
                  default:
                    return null;
                }
              }}
            />
          </Box>
          <Box sx={sx.labelWrapper}>
            <Typography sx={sx.label}>{label}</Typography>
            {additionalInfo && <Typography sx={sx.aditionalInfo}>{additionalInfo}</Typography>}
          </Box>
        </>
      )}
    </Paper>
  );
};

const sx: SxStyles = {
  loadingWrapper: {
    margin: "auto",
  },
  itemContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    maxWidth: "100%",
    minWidth: "200px",
    width: "300px",
    minHeight: "170px",
    padding: "24px 30px",
  },
  info: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    minWidth: 0,
  },
  value: {
    fontSize: "3.5rem",
    fontWeight: 600,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "wrap",
  },
  label: {
    fontSize: "1.5rem",
    fontWeight: 500,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "wrap",
  },
  icon: {
    fontSize: "5rem",
  },
  labelWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  aditionalInfo: {
    display: "flex",
    alignItems: "center",
    fontSize: "12px",
    fontWeight: 400,
  },
};
