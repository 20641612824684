import { Components, createTheme, PaletteColorOptions, SxProps, Theme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface CustomPalette {
    navyBlueDark: PaletteColorOptions;
    darkBlue: PaletteColorOptions;
    mintGreen: PaletteColorOptions;
    oceanBlue: PaletteColorOptions;
    skyBlue: PaletteColorOptions;
    dustyRose: PaletteColorOptions;
    calmBlue: PaletteColorOptions;
    lavender: PaletteColorOptions;
  }
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}
}

export const colors = {
  beige: "#E2C59C",
  darkBeige: "#B39B7B",
  lightBlack: "#242424",
  darkBlue: "#111424",
  navyBlue: "#434274",
  navyBlueDark: "#2B2A53",
  blue: "#191D36",
  black: "#242424",
  white: "#FFFFFF",
  lightgrey: "#F7F7F7",
  backgroundGradient:
    "linear-gradient(180deg, #121526 0%, #14182C 20.57%, #202546 45.27%, #403F61 66.73%, #6B5B64 81.51%, #977A6A 91.56%, #BEA693 100%)",
};

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor: string) => augmentColor({ color: { main: mainColor } });

export const theme: Theme = createTheme({
  typography: {
    fontFamily: ["Roboto", "sans-serif"].join(","),
    fontSize: 14,
    fontWeightRegular: 300,
    button: {
      textTransform: "none",
      fontWeight: 600,
    },
  },
  palette: {
    navyBlueDark: createColor(colors.navyBlueDark),
    darkBlue: createColor("#3E3D6B"),
    mintGreen: createColor("#C2E6C8"),
    oceanBlue: createColor("#89B8E3"),
    skyBlue: createColor("#9DC5EA"),
    dustyRose: createColor("#E2B0B0"),
    calmBlue: createColor("#B8D5F2"),
    lavender: createColor("#E2E2EA"),
    primary: {
      main: colors.beige,
      dark: colors.darkBeige,
      light: colors.white,
    },
    secondary: {
      main: colors.navyBlue,
      light: colors.lightgrey,
    },
    common: {
      black: colors.black,
      white: colors.white,
    },
    background: {
      default: colors.darkBlue,
    },
  },
});

const themeComponentsOverrides: (theme: Theme) => Components = ({ palette }: Theme) => {
  return {
    MuiButton: {
      defaultProps: { variant: "contained" },
      styleOverrides: {
        contained: {
          backgroundColor: colors.navyBlue,
          color: colors.white,
          "&:hover": { backgroundColor: colors.navyBlueDark },
        },
        text: {
          fontWeight: 300,
          fontSize: 14,
        },
      },
    },
    MuiTextField: {
      defaultProps: { size: "small", variant: "standard", color: "secondary" },
    },
    MuiRadio: { defaultProps: { color: "secondary" } },
    MuiSelect: { defaultProps: { color: "secondary" } },
    MuiTypography: {
      styleOverrides: { h2: { color: colors.white, fontSize: 34, ml: 0, mr: "auto" } },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          maxWidth: "1200px",
          width: "100%",
          maxHeight: "80%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflowX: "hidden",
          mx: "auto",
        },
      },
    },
    MuiInputLabel: { defaultProps: { color: "secondary" } },
  };
};

theme.components = themeComponentsOverrides(theme);

export type SxStyles = Record<string, SxProps<Theme>>;
