// import { SxStyles } from "../../theme";
import { Header } from "../../components/Header/Header";
import { Container } from "../../components/Container/Container";
import { Statistics } from "../../components/Statistics/Statistics";
import {
  StatisticItemProps,
  StatisticItemColor,
} from "../../components/StatisticItem/StatisticItem.types";
import { Box } from "@mui/material";
import { SxStyles } from "../../theme";
import { TABLE_HEIGHT } from "../../helpers/sx";
import { useGetStatisticsQuery } from "../../graphql/client";
import { useMemo } from "react";

export function PageDashboard() {
  const { data, isFetching } = useGetStatisticsQuery(
    {},
    { onError: (err) => console.error("error:", err) }
  );

  const statistics: StatisticItemProps[] = useMemo(
    () => [
      {
        label: "Active users",
        value: data?.statistics.statistics.bigQueryStatistics?.activeUsers,
        icon: "activeUsers",
        color: StatisticItemColor.MintGreen,
        additionalInfo: `${data?.statistics.statistics.bigQueryStatistics?.dateFrom} - ${data?.statistics.statistics.bigQueryStatistics?.dateTo}`,
        isLoading: isFetching,
      },
      {
        label: "Therapy activated",
        value: data?.statistics.statistics.treatmentStatistics?.usersWithTreatment,
        icon: "therapyActivated",
        color: StatisticItemColor.Lavender,
        isLoading: isFetching,
      },
      {
        label: "Monthly subscriptions",
        value: data?.statistics.statistics.revenueCatStatistics?.activeMonthlySubscriptionsCount,
        icon: "monthlySubscription",
        color: StatisticItemColor.CalmBlue,
        isLoading: isFetching,
      },
      {
        label: "Annual subscriptions",
        value: data?.statistics.statistics.revenueCatStatistics?.activeAnnualSubscriptionsCount,
        icon: "annualSubscription",
        color: StatisticItemColor.SkyBlue,
        isLoading: isFetching,
      },
      {
        label: "Total subscriptions",
        value: data?.statistics.statistics.revenueCatStatistics?.usersBoughtSubscriptionCount,
        icon: "subscription",
        color: StatisticItemColor.OceanBlue,
        isLoading: isFetching,
      },
    ],
    [data, isFetching]
  );

  return (
    <Container>
      <Header>Dashboard</Header>
      <Box sx={sx.statistics}>
        <Statistics items={statistics} />
      </Box>
    </Container>
  );
}

const sx: SxStyles = {
  statistics: {
    display: "flex",
    flexDirection: "column",
    height: `${TABLE_HEIGHT}px`,
    maxHeight: "100%",
    padding: "25px",
  },
};
