import { Box } from "@mui/material";
import { StatisticsProps } from "./Statistics.types";
import { SxStyles } from "../../theme";
import { StatisticItem } from "../StatisticItem/StatisticItem";
export const Statistics = ({ items }: StatisticsProps) => {
  return (
    <Box sx={sx.itemsContainer}>
      {items.map((item, index) => {
        return (
          <StatisticItem
            key={index}
            label={item.label}
            value={item.value}
            icon={item.icon}
            color={item.color}
            additionalInfo={item?.additionalInfo}
            isLoading={item.isLoading}
          />
        );
      })}
    </Box>
  );
};

const sx: SxStyles = {
  itemsContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "24px",
    justifyContent: "center",
    maxWidth: "100%",
    "& > div:first-of-type": {
      flex: "30%",
      maxWidth: "315px",
    },
    "& > div:nth-of-type(2)": {
      flex: "30%",
      maxWidth: "315px",
    },
  },
};
