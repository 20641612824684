/* eslint-disable no-unused-vars */
export type StatisticItemProps = {
  label: string;
  value: number | undefined;
  icon: StatisticIcon;
  color: StatisticItemColor;
  additionalInfo?: string;
  isLoading: boolean;
};
export enum StatisticItemColor {
  MintGreen = "mintGreen",
  OceanBlue = "oceanBlue",
  SkyBlue = "skyBlue",
  DustyRose = "dustyRose",
  CalmBlue = "calmBlue",
  Lavender = "lavender",
}
export type StatisticIcon =
  | "activeUsers"
  | "subscription"
  | "churn"
  | "annualSubscription"
  | "monthlySubscription"
  | "therapyActivated";
